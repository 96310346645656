import React, { Component } from 'react';
import closeButton from "./cancel.svg";
import "./ControlPanel.css";

export class ControlPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    componentWillReceiveProps(props) {
        this.setState({ show: props.show });
    }

    render() {
        let classNames = "controlPanel " + (this.state.show ? "slideIn" : "fadeOut");

        return (
            <div className={classNames}>
                <div className="panelHeader">
                    <img className="closeButton" src={closeButton} alt="Back" onClick={this.props.onMenuClosed} />
                    <h4>{this.props.title}</h4>
                </div>

                {this.props.children}
            </div>
        );
    }
}