import * as resourceLoader from '../resourceLoader';

export class EffectSceneObject {
    constructor(fabric, canvas, effect) {
        this.fabric = fabric;
        this.effect = effect;
        this.canvas = canvas;
        this.canvasObject = null;
        this.frames = null;
        this.frozenFrame = null;
    }

    getCanvasObject() {
        return this.canvasObject;
    }

    getFrame(frameNum) {
        let that = this;
        let realFrameNum = (that.frozenFrame) ? (that.frozenFrame % that.effect.numberOfFrames) : (frameNum % that.effect.numberOfFrames);

        return new Promise((resolve, reject) => {
            if (!that.frames) {
                that.fabric.Image.fromURL(that.effect.previewFrame.url, function(effectFrame) {
                    that.canvasObject = effectFrame;
                    let width = that.canvas.getWidth();
                    let height = that.canvas.getHeight();

                    if (that.effect.fullScreen) {
                        effectFrame.scaleToWidth(width);
                        effectFrame.scaleToHeight(height);
                    } else {
                        effectFrame.scaleToWidth(100);
                        effectFrame.scaleToHeight(100);
                        effectFrame.set({ left: width/2 - 50, top: height/2 - 50 });
                    }

                    that.canvas.add(effectFrame);
                    that.canvas.setActiveObject(effectFrame);
                    console.log("Effect added to canvas");

                    resourceLoader.loadEffectFrames(that.effect, function(frames) {
                        effectFrame.setSrc(frames[realFrameNum], function() {
                            effectFrame.applyFilters();
                            that.frames = frames;
                            resolve();
                        });
                    });
                }, { crossOrigin: 'anonymous' });
            } else {
                that.canvasObject.setSrc(that.frames[realFrameNum], function() {
                    resolve();
                });
            }
        });
    }
}