import React from 'react';
import {fabric} from 'fabric';
import { EffectSceneObject } from '../../lib/rendering/EffectSceneObject';
import { TextSceneObject } from '../../lib/rendering/TextSceneObject';
import { ShapeSceneObject } from '../../lib/rendering/ShapeSceneObject';
import * as FontFaceObserver from 'fontfaceobserver';
import './VimageScene.css'
import watermark from './watermark.png';
import { NativeEncoder } from '../../lib/NativeEncoder';
import { FfmpegEncoder } from '../../lib/FfmpegEncoder';

export class VimageScene extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fabricCanvas: null,
            imageAlreadyLoaded: false
        }
        this.canvasImage = null;
        this.effects = [];
        this.encoding = false;
        this.livePreview = false;
        this.frameCounter = 0;
        this.encoder = new NativeEncoder("main-canvas", 30);
        this.videoConverter = new FfmpegEncoder();
        this.vimageStream = null;
    }

    onExport() {
        if (this.state.fabricCanvas.getObjects().length <= 2) {
            return;
        }
        
        this.state.fabricCanvas.discardActiveObject();
        document.getElementById("div-vimage-scene").style.pointerEvents = 'none';
        this.encoding = true;
    }

    onPreview() {
        this.livePreview = !this.livePreview;
        this.canvasRenderLoop();
    }

    peekFrame(step) {
        let activeObjectIndex = this.state.fabricCanvas.getObjects().indexOf(this.state.fabricCanvas.getActiveObject());

        if (activeObjectIndex === -1) {
            return;
        }

        if (this.effects[activeObjectIndex - 2].frozenFrame) {
            this.effects[activeObjectIndex - 2].frozenFrame += step;
        }
    }

    onSeekForward() {
        this.peekFrame(+1);
    }

    onSeekBackward() {
        this.peekFrame(-1);
    }

    render() {
        return (
            <div id="div-vimage-scene" className="vimageScene">
                <canvas id="main-canvas" />
                <canvas id="preview-canvas" hidden={true} />
            </div>
        );
    }

    canvasRenderLoop() {
        let that = this;
        let targetRenderTime = 1/30*1000;
        let encodingRenderTime = 1/15*1000;
        let startTime = 0;
        let encodingCounter = 0;
        let glitchAvoidenceCounter = 0;

        function update() {
            if (!that.livePreview) {
                return;
            }

            startTime = Date.now();
            let effectRenderTasks = [];
            that.frameCounter %= 150;

            that.effects.forEach((effect) => {
                effectRenderTasks.push(effect.getFrame(that.frameCounter).then(() => {
                    return true;
                }))
            });

            Promise.all(effectRenderTasks).then(() => {
                let renderTime = Date.now() - startTime;
                that.canvasImage.center();

                if (that.encoding) {
                    that.encoder.pause();

                    //@fix: On Mac Chrome the 104th frame is glitching, so we try to not encode at that time.
                    if (glitchAvoidenceCounter > 0 && glitchAvoidenceCounter < 20) {
                        glitchAvoidenceCounter++
                    } else {
                        that.state.fabricCanvas.renderAll();
                        that.props.onExportProgress(encodingCounter/160*100);

                        try {
                            that.encoder.resume();
                        } catch (e) {

                        }

                        encodeFrame();
                        that.frameCounter++;

                    }

                    setTimeout(() => {
                        update();
                    }, encodingRenderTime);
                } else {
                    if (renderTime < targetRenderTime) {
                        setTimeout(() => {
                            that.frameCounter++;
                            that.state.fabricCanvas.renderAll();
                            update();
                        }, targetRenderTime - renderTime);
                    } else {
                        that.frameCounter++;
                        that.state.fabricCanvas.renderAll();
                        update();
                    }
                }
            });
        }

        function encodeFrame() {
            if (encodingCounter === 0) {
                that.vimageStream = that.encoder.start(function(blob, filename) {
                    glitchAvoidenceCounter = 0;

                    that.videoConverter.webmToMp4(blob, filename, function() {
                        that.props.onExportProgress(100);
                        that.props.onExportProgress(0);
                    });
                });

                that.vimageStream.getVideoTracks()[0].requestFrame();
                encodingCounter++;
            //@fix: On Mac Chrome the 104th frame is glitching, so we try to no encode at that time.
            } else if (encodingCounter == 100 && glitchAvoidenceCounter == 0) {
                glitchAvoidenceCounter++;
            } else if (encodingCounter === 152) {
                that.vimageStream.getVideoTracks()[0].requestFrame();
                that.encoding = false;
                document.getElementById("div-vimage-scene").style.pointerEvents = 'auto';
                encodingCounter = 0;
                that.encoder.encode();
            } else {
                that.vimageStream.getVideoTracks()[0].requestFrame();
                encodingCounter++;
            }
        }

        update();
    }

    addEffect(effect) {
        let effectSceneObject = new EffectSceneObject(fabric, this.state.fabricCanvas, effect);
        this.effects.push(effectSceneObject);

        if (!this.livePreview) {
            this.onPreview();
        }
    }

    addRectangle(color) {
        var rect = new fabric.Rect({
            left: 50,
            top: 50,
            width: 400,
            height: 400,
            fill: 'rgba(0,0,0,0)',
            stroke: 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')',
            strokeWidth: 5
         });

        this.state.fabricCanvas.add(rect);
        this.state.fabricCanvas.setActiveObject(rect);
        this.effects.push(new ShapeSceneObject());

        return rect;
    }

    addTriangle(color) {
        var triangle = new fabric.Triangle({
            left: 50,
            top: 50,
            width: 400,
            height: 400,
            fill: 'rgba(0,0,0,0)',
            stroke: 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')',
            strokeWidth: 5
         });

        this.state.fabricCanvas.add(triangle);
        this.state.fabricCanvas.setActiveObject(triangle);
        this.effects.push(new ShapeSceneObject());

        return triangle;
    }

    addCircle(color) {
        var circle = new fabric.Circle({
            left: 50,
            top: 50,
            radius: 50,
            fill: 'rgba(0,0,0,0)',
            stroke: 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + color.a + ')',
            strokeWidth: 5
         });

        this.state.fabricCanvas.add(circle);
        this.state.fabricCanvas.setActiveObject(circle);
        this.effects.push(new ShapeSceneObject());

        return circle;
    }

    duplicateActiveObject() {
        let activeObjectIndex = this.state.fabricCanvas.getObjects().indexOf(this.state.fabricCanvas.getActiveObject());
        let activeSceneObject = Object.assign({}, this.effects[activeObjectIndex - 1].effect);
        this.addEffect(activeSceneObject);
    }

    toggleFreezeObject() {
        let activeObjectIndex = this.state.fabricCanvas.getObjects().indexOf(this.state.fabricCanvas.getActiveObject());

        if (activeObjectIndex === -1) {
            return;
        }

        this.effects[activeObjectIndex - 2].frozenFrame = this.effects[activeObjectIndex - 2].frozenFrame  ? null : this.frameCounter;
    }

    deleteActiveObject() {
        let activeObjectIndex = this.state.fabricCanvas.getObjects().indexOf(this.state.fabricCanvas.getActiveObject());
        this.state.fabricCanvas.remove(this.state.fabricCanvas.getActiveObject());
        this.effects.splice(activeObjectIndex - 2, 1);
        this.props.onObjectDeleted(activeObjectIndex - 2);

        if (!this.livePreview) {
            this.livePreview = true;
            this.canvasRenderLoop();
        }
    }

    addText(text) {
        let that = this;
        let font = new FontFaceObserver(text.fontFamily);

        return font.load().then(function() {
            let fabricText = new fabric.IText(text.text, {
                fontFamily: text.fontFamily,
                letf: text.left,
                right: text.right,
                fill: text.color
            });
    
            that.state.fabricCanvas.add(fabricText);
            fabricText.set({left: that.state.fabricCanvas.getWidth() / 2, top: that.state.fabricCanvas.getHeight() / 2 });
            that.effects.push(new TextSceneObject());
            that.state.fabricCanvas.setActiveObject(fabricText);
            
            return fabricText;
        });
    }

    componentDidMount() {
        let canvas = new fabric.Canvas('main-canvas');

        canvas.setWidth(0);
        canvas.setHeight(0);
        this.setState({
            fabricCanvas: canvas
        });
    }

    componentDidUpdate() {
        this.handleSceneChanged();
    }

    setSelectedObject(index) {
        const objects = this.state.fabricCanvas.getObjects();

        for (let i = 1; i < objects.length; i++) {
            if (i === index + 2) {
                this.state.fabricCanvas.setActiveObject(objects[i]);
                this.state.fabricCanvas.renderAll();
                break;
            }
        }
    }

    shouldComponentUpdate(nextProps) {
        return true;
    }

    handleSceneChanged() {
        let that = this;

        //Loading image
        if (this.props.image && !this.state.imageAlreadyLoaded) {
            this.setState({imageAlreadyLoaded: true});

            fabric.Image.fromURL((typeof this.props.image === "string") ? this.props.image : URL.createObjectURL(this.props.image), function(oImg) {
                that.canvasImage = oImg;
                let aspectRatio = oImg.width / oImg.height;
                let newWidth = 768;
                let newHeight = 640;

                if (aspectRatio > 1) {
                    newHeight = newWidth / aspectRatio;
                } else {
                    newWidth = newHeight * aspectRatio;
                }

                fabric.Image.fromURL(watermark, function(logo) {
                    logo.scaleToWidth(newWidth);
                    logo.selectable = false;
                    that.state.fabricCanvas.add(logo);
                    logo.set({ left: 0, top: newHeight - logo.getScaledHeight()});
                });

                console.log("Aspect ratio:" + aspectRatio);
                console.log("Width: " + newWidth);
                console.log("Height: " + newHeight);
                that.state.fabricCanvas.setWidth(newWidth);
                that.state.fabricCanvas.setHeight(newHeight);
                oImg.scaleToWidth(newWidth);
                oImg.scaleToHeight(newHeight);
                oImg.selectable = false;
                that.state.fabricCanvas.add(oImg);
                oImg.center();
            }, { crossOrigin: 'anonymous'});
        }
    }
}