function _arrayBufferToBase64 (bytes) {
    let binary = '';
    let len = bytes.byteLength;

    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binary);
}

export function loadEffectFrames(effect, cb) {
    let xhr = new XMLHttpRequest();
    let frames = [];
    xhr.open('get', effect.buffer.url);
    xhr.responseType = 'arraybuffer';
    xhr.onload = function (e) {
        let buffer = new Uint8Array(this.response);
        let counter = 0;

        for (let i = 0; i < effect.frameSizesInBytes.length; i++) {
            let frameSize = effect.frameSizesInBytes[i];
            let frame = buffer.slice(counter, counter + frameSize - 1);
            frames.push("data:image/png;base64," + _arrayBufferToBase64(frame));
            counter += frameSize;
        }

        cb(frames);
    }

    xhr.send();
}

export function loadScript(src) {
    return new Promise(function(resolve, reject) {
      const s = document.createElement('script');
      let r = false;
      s.type = 'text/javascript';
      s.src = src;
      s.async = true;
      s.onerror = function(err) {
        reject(err, s);
      };
      s.onload = s.onreadystatechange = function() {
        // console.log(this.readyState); // uncomment this line to see which ready states are called.
        if (!r && (!this.readyState || this.readyState === 'complete')) {
          r = true;
          resolve();
        }
      };
      const t = document.getElementsByTagName('script')[0];
      t.parentElement.insertBefore(s, t);
    });
  }