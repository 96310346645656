import React from 'react';
import { VideoPlayer } from '../VideoPlayer/VideoPlayer';
import './MobileView.css';

export class MobileView extends React.Component {

  render() {
    const videoJsOptions = {
      autoplay: true,
      controls: false,
      muted: true,
      loop: true,
      responsive: true,
      fluid:true,
      sources: [{
        src: '/background.mp4',
        type: 'video/mp4'
      }]
    }

    return (
    <div className="container"> 
      <div className="overlay title">
        <img className="imgTitle" src="/logo.png" width={307} height={65} />
        <div className="description vertical-center">
          <h4 className="subtitleText">VIMAGE Online is exlusive to Desktop. Visit again on your PC or Mac, or try our mobile apps.</h4>
          <a href="https://play.google.com/store/apps/details?id=com.vimage.android&amp;referrer=utm_source%3Dwebsite%26utm_medium%3Dbutton" 
            target="_blank" 
            rel="noopener">
              <img src="https://vimageapp-prod.nyc3.cdn.digitaloceanspaces.com/marketing/play_store_badge.png"
                  alt=""
                  width="200" />
          </a>
          <a href="https://itunes.apple.com/hu/app/vimage/id1291728987?l=hu&amp;mt=8" 
            target="_blank"
            rel="noopener">
              <img
                src="https://vimageapp-prod.nyc3.cdn.digitaloceanspaces.com/marketing/app_store_badge_300x89.png" 
                className="appStoreBadge"
                alt="" 
                width="200" />
          </a>
        </div>
      </div>
      <div className="overlay" width={307} height={65}>
      <VideoPlayer { ...videoJsOptions } />
      </div>
    </div>
    )
  }
}