const WEB_FONTS_API_KEY = "AIzaSyBQ-4Q10U3y03fF6OEcl-nN3GZOhLFnNLo";


export function getEffects() {
    return fetch("https://us-central1-vimageapp-dev.cloudfunctions.net/effects")
        .then(response => response.json())
        .then(data => {
            let effects = [];

            for (let key in data.payload.effects) {
                let effect = data.payload.effects[key];
                effect.dbKey = key;
                effects.push(effect);
            }

            return effects;
        });
}

export function getWebfonts() {
    return fetch(`https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=${WEB_FONTS_API_KEY}`)
    .then(response => response.json());
}