import React, { Component } from "react";
import { Line } from 'rc-progress';
import "./ExportPanel.css";

export class ExportPanel extends Component {

    render() {
        return (
            <div>
                { !this.props.isHidden ?
                    <div className="exportPanel">
                        { this.props.exportProgress > 0 && this.props.exportProgress < 100 ?
                            <Line percent={this.props.exportProgress} 
                                strokeWidth="4" 
                                trailWidth="4" 
                                strokeColor="#138CE5" /> :
                            <div>
                                <a className="vimageButton previewButton"  onClick={this.props.onPreviewClicked}>Preview</a>
                                <a className="vimageButton exportButton" onClick={this.props.onExportClicked}>Export</a>
                            </div>
                        }
                    </div> : null
                }
            </div>
        )
    }
}