import React, { Component } from 'react';
import { VimageScene } from './components/VimageScene/VimageScene';
import { PickPhoto } from './components/PickPhoto/PickPhoto';
import { ControlPanel } from './components/ControlPanel/ControlPanel';
import { EffectSelection } from './components/EffectSelection/EffectSelection';
import { TextEditor } from './components/TextEditor/TextEditor';
import { Toolbar } from './components/Toolbar/Toolbar';
import { ExportPanel } from './components/ExportPanel/ExportPanel';
import { LayerPanel } from './components/LayerPanel/LayerPanel';
import { ShapeSelection } from './components/ShapeSelection/ShapeSelection';
import { SettingsMenu } from './components/SettingsMenu/SettingsMenu';
import { MobileView } from './components/MobileView/MobileView';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { detect } from 'detect-browser';
import 'logo.svg';
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      objects: [],
      menuSelected: null,
      exportProgress: 0
    }

    this.handleImageSelected = this.handleImageSelected.bind(this);
    this.handleEffectSelected = this.handleEffectSelected.bind(this);
    this.handleMenuSelected = this.handleMenuSelected.bind(this);
    this.handleTextAdded = this.handleTextAdded.bind(this);
    this.handleExportClicked = this.handleExportClicked.bind(this);
    this.handleLoadUrlClicked = this.handleLoadUrlClicked.bind(this);
    this.handlePreviewClicked = this.handlePreviewClicked.bind(this);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
    this.handleLayerItemSelected = this.handleLayerItemSelected.bind(this);
    this.handleExportProgress = this.handleExportProgress.bind(this);
    this.handleObjectDetected = this.handleObjectDetected.bind(this);
    this.handleShapeSelected = this.handleShapeSelected.bind(this);
    this.handleMenuClosed = this.handleMenuClosed.bind(this);
  }

  handleImageSelected(e) {
    this.setState({
      image: e.target.files[0]
    })
  }

  handleKeyPressed(key) {
    if (key === 'space') {
      this.scene.toggleFreezeObject();
    }

    if (key === 'right') {
      this.scene.onSeekForward();
    }
    
    if (key === 'left') {
      this.scene.onSeekBackward();
    }
    
    if (key === 'ctrl+c') {
      this.scene.duplicateActiveObject();
    }

    if (key === 'backspace') {
      this.scene.deleteActiveObject();
    }
  }

  handleTextAdded(e, text) {
    let textObj = { 
      text: text.text,
      fontFamily: text.fontFamily,
      color: text.color
    };

    this.scene.addText(textObj)
    .then((fabricText) => {
      let textObjCpy = Object.assign({}, textObj);
      textObjCpy.previewFrame = { url: fabricText.toDataURL() }; 

      this.setState(prevState => ({
        objects: [...prevState.objects, textObjCpy]
      }));
    });
  }

  handleShapeSelected(evt, shape) {
    const shapeType = shape.shapeType;
    const color = shape.color;
    let fabricShape = null;

    if (shapeType === "triangle") {
      fabricShape = this.scene.addTriangle(color);
    } else if (shapeType === "rectangle") {
      fabricShape = this.scene.addRectangle(color);
    } else if (shapeType === "circle") {
      fabricShape = this.scene.addCircle(color);
    }

    let shapeObjCopy = Object.assign({}, fabricShape);
    shapeObjCopy.previewFrame = { url: fabricShape.toDataURL() }; 

    this.setState(prevState => ({
      objects: [...prevState.objects, shapeObjCopy]
    }));
  }

  handleObjectDetected(objectIndex) {
    let objectsCpy = [...this.state.objects];
    objectsCpy.splice(objectIndex, 1);

    this.setState({
      objects: objectsCpy,
      menuSelected: null
    });
  }

  handleMenuSelected(e, menu) {
    this.setState({ menuSelected: menu });
  }
  
  handleMenuClosed(e) {
    this.setState({ menuSelected: null });
  }

  handleExportClicked() {
    this.scene.onExport();
  }
  
  handlePreviewClicked() {
    this.scene.onPreview();
  }

  handleEffectSelected(evt, effect) { 
    this.scene.addEffect(effect);
    this.setState(prevState => ({
      objects: [...prevState.objects, effect]
    }));
  }

  handleExportProgress(progress) {
    this.setState({exportProgress: progress, menuSelected: null});
  }

  handleLoadUrlClicked(url) {
    this.setState({
      image: url
    })
  }

  handleLayerItemSelected(e, index) {
    this.scene.setSelectedObject(index);
  }

  render() {
    let menuSelected = this.state.menuSelected || {type: null, title: null};
    let title = menuSelected.title;
    let controlPanelContent = null;
    let app = null;

    if (menuSelected.type === "EFFECT_MENU") {
      controlPanelContent = <EffectSelection onEffectSelected={this.handleEffectSelected} />
    } else if (menuSelected.type === "TEXT_MENU") {
      controlPanelContent = <TextEditor onTextAdded={this.handleTextAdded} />
    } else if (menuSelected.type === "SHAPES_MENU") {
      controlPanelContent = <ShapeSelection onShapeSelected={this.handleShapeSelected} />
    } else if (menuSelected.type === "SETTINGS_MENU") {
      controlPanelContent = <SettingsMenu />
    } else if (menuSelected.type === "BRAND_MENU") {
      //TODO: add your brand maybe??
    }

    const showControlpanel = !!controlPanelContent;
    const browser = detect();
    
    if (browser.os === "Android OS" || browser.os === "BlackBerry OS" || browser.os === "Windows Mobile" || browser.os === "iOS") {
      app = <MobileView />;
    } else if (browser.name === "chrome") {
      app = <div className="vimage">
        <KeyboardEventHandler
            handleKeys={['space', 'left', 'right', 'backspace', 'ctrl+c']}
            onKeyEvent={(key, e) => this.handleKeyPressed(key) } />
        <Toolbar onMenuSelected={this.handleMenuSelected} 
                onMenuClosed={this.handleMenuClosed}
                isMenuClosed={!showControlpanel}
                isHidden={this.state.image == null || this.state.exportProgress > 0} />
        <ControlPanel 
            show={ showControlpanel && this.state.exportProgress == 0 }
            onMenuClosed={this.handleMenuClosed} 
            title={title}>
          {controlPanelContent}
        </ControlPanel>
        <PickPhoto onChange={this.handleImageSelected} 
                  onLoadUrlClicked={this.handleLoadUrlClicked} />
        <ExportPanel onExportClicked={this.handleExportClicked} 
                    onPreviewClicked={this.handlePreviewClicked}
                    exportProgress={this.state.exportProgress}
                    isHidden={this.state.image==null} />
        <VimageScene image={this.state.image} 
                    objects={this.state.objects}
                    onObjectDeleted={this.handleObjectDetected}
                    onExportProgress={this.handleExportProgress}
                    ref={(scene) => { this.scene = scene; }} />
        <LayerPanel isHidden={this.state.image == null || this.state.exportProgress > 0} 
                    objects={this.state.objects}
                    onLayerItemSelected={this.handleLayerItemSelected} />
        </div>
    } else {
      window.location.href = "http://vimageapp.com";
    }

    return (
      app
    );
  }
}

export default App;
