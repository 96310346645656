import React from 'react';
import './PickPhoto.css'
import '../ExportPanel/ExportPanel.css';

export class PickPhoto extends React.Component {
    constructor(props) {
        super(props);
        this.inputElement = React.createRef();
        this.urlInput = React.createRef();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = (e) => {
        this.inputElement.click();
    }

    render() {
        return (
            <div className="pickPhotoBtnContainer">
                <div className="photoStuff">
                    <a onClick={this.handleClick} className="openImageLabel" href="javascript:void()">Open image</a>
                    <p className="orLabel"> or</p>
                    <input type="text" className="photoUrl" placeholder="http://example.com/image.jpg" ref={input => this.urlInput = input} />
                    <a className="vimageButton exportButton" onClick={ e => { this.props.onLoadUrlClicked(this.urlInput.value)}}>Load URL</a>
                </div>
                <input ref={input => this.inputElement = input} 
                    type="file"
                    accept="image/png, image/jpeg" 
                    hidden={true} 
                    onChange={this.props.onChange} />
            </div>
        );
    }
}