import React, { Component } from "react";
import "./LayerItem.css";

export class LayerItem extends Component {
    render() {
        return (
            <div className="layerItem" onClick={this.props.onItemSelected}>
              <img src={this.props.preview} width={60} height={60} alt={this.props.name} /> 
            </div>
        )
    }
}