import React, { Component } from "react";
import { ChromePicker } from 'react-color'
import "./ShapeSelection.css";

export class ShapeSelection extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      shapes: [
        { name: "Rectangle", type: "rectangle" },
        { name: "Triangle", type: "triangle" },
        { name: "Circle", type: "circle" }
      ],
      selectedShape: "rectangle",
      strokeColor: {
        r: '255',
        g: '255',
        b: '255',
        a: '1',
      }
    }

    this.shapeSelectChange = this.shapeSelectChange.bind(this);
    this.handleColorChangeComplete = this.handleColorChangeComplete.bind(this);
  }

  handleColorChangeComplete = (color, event) => {
    this.setState({ strokeColor: color.rgb });
  };

  shapeSelectChange(event) {
    this.setState({ selectedShape: event.target.value });
  }

  render() {
    let that = this;

    return (
      <div>
        <label className="controlLabel">TYPE</label>
        <select className="shapeSelect" onChange={this.shapeSelectChange} value={this.state.selectedShape}>
          {this.state.shapes.map((shape, index) => <option key={index} value={shape.type}>{shape.name}</option>)}
        </select>

        <label className="controlLabel">COLOR</label>
        <ChromePicker color={this.state.strokeColor} 
                      onChangeComplete={this.handleColorChangeComplete}
                      className="colorPicker" />

        <input type="button" 
              onClick={(e) => {
                this.props.onShapeSelected(e, { shapeType: that.state.selectedShape, color: that.state.strokeColor });
              }}
              className="addBtn"
              value="ADD"></input>
      </div>
    );
  }
}