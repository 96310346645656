import VideoConverterWorker from './worker/VideoConverterWorker';
import WebWorker from './worker/WebWorker';
import { saveAs } from 'file-saver';

const BASE64_MARKER = ';base64,';
const MAX_ASM_MEMORY = 512435456;

function toUint8Array(dataURI) {
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }

    return array;
}

export class FfmpegEncoder {
    constructor(canvas) {
        this.frames = [];
        this.canvas = canvas;
        this.worker = new WebWorker(VideoConverterWorker);
    }

    sendFrame(index) {
      this.frames.push({
          data: toUint8Array(document.getElementById(this.canvas).toDataUrl({format: "png"})),
          name: `frame_${index.toString().padStart(4, "0")}.png`
      });
    }

    webmToMp4(blob, filename, cb) {
        let that = this;

        if (that.worker == null) {
            that.worker = new WebWorker(VideoConverterWorker);
        }

        that.worker.postMessage({
            arguments: ["-i", filename, "-vcodec", "copy", `${Date.now()}.mp4`],
            files: [{ data: blob, name: filename}],
            type: "command",
            TOTAL_MEMORY: MAX_ASM_MEMORY
        });

        that.worker.addEventListener('message', event => {
            let message = event.data;
            
            if (message.type === "done") {
                var blob = new Blob([message.data[0].data], {type: "application/octet-stream"});
                var fileName = message.data[0].name;
                saveAs(blob, fileName);
                that.worker.terminate();
                that.worker = null;
                cb();
            }
        });
    }

    encode() {
      let that = this;

      that.worker.postMessage({
          arguments: ["-i", "frame_%04d.png", "-c:v", "libx264", "-pix_fmt", "yuv420p", "-profile:v", "baseline", "-level", "3", "-crf", "1", `${Date.now()}.mp4`],
          files: that.frames,
          type: "command",
          TOTAL_MEMORY: MAX_ASM_MEMORY
      });

      that.worker.addEventListener('message', event => {
          let message = event.data;

          if (message.type === "done") {
              var blob = new Blob([message.data[0].data], {type: "application/octet-stream"});
              var fileName = message.data[0].name;
              saveAs(blob, fileName);
              that.worker.terminate();
              that.frames = [];
          }
      });
    }
}

