import { saveAs } from 'file-saver';
import * as timelapse  from "./Timelapase";

export class NativeEncoder {
    constructor(canvas, fps) {
        this.chunks = [];
        this.canvas = canvas;
        this.fps = fps;
        this.recorder = null;
    }

    resume() {
      if (!this.recorder) {
        return null;
      }

      this.recorder.resume();
    }

    pause() {
      if (!this.recorder) {
        return null;
      }

      this.recorder.pause();
    }

    start(cb) {
      let that = this;
      let vimageStream = document.getElementById(this.canvas).captureStream(0);
      this.recorder = new MediaRecorder(vimageStream, { mimeType: "video/webm;codecs=h264" });
      this.recorder.start();
      this.recorder.ondataavailable = (e) => {
        that.chunks.push(e.data);
      };

      this.recorder.onstop = () => {
        timelapse.retime(that.chunks, 30, function (blob) {
          that.chunks = [];
          that.recorder = null;

          new Response(blob)
            .arrayBuffer()
            .then(function(buffer) {
              cb(new Uint8Array(buffer), `${Date.now()}.webm`);
            });
          });
      }

      return vimageStream;
    }

    encode() {
      this.recorder.stop();
    }
}