import React, { Component } from 'react';
import "./Toolbar.css";
import "../EffectSelectionItem/EffectSelectionItem";
import effectButtonLogo from "./effect_icon.svg";
import textButtonLogo from "./text_icon.svg";
import vimageDarkLogo from "../../vimage_dark_logo.png"
import frameButtonLogo from "./frame.svg";
import filterButtonLogo from "./filter_icon.svg";
import settingsButtonLogo from "./settings.svg";
import geometryButtonLogo from './geometry.svg';
import { ToolbarItem } from '../ToolbarItem/ToolbarItem';

export class Toolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: null
        };

        this.setActiveItem = this.setActiveItem.bind(this);
    }

    setActiveItem(e, item) {
        if (this.state.activeItem && item.type === this.state.activeItem.type) {
            this.setState({activeItem: null});
            this.props.onMenuClosed(e);
        } else {
            this.setState({activeItem: item});
            this.props.onMenuSelected(e, item);
        }
    }

    componentWillReceiveProps(props) {
        if (props.isMenuClosed) {
            this.setState({activeItem: null});
        }
    }

    render() {
        const listItems = 
        [{ title: "EFFECTS",type:"EFFECT_MENU", logo: effectButtonLogo, avail: true }, 
         { title: "TEXTS", type:"TEXT_MENU", logo: textButtonLogo, avail: true },
         { title: "SHAPES", type:"SHAPES_MENU", logo: geometryButtonLogo, avail: true},
         { title: "FRAMES", type:"FRAMES_MENU", logo: frameButtonLogo, avail: false },
         { title: "FILTERS", type:"FILTERS_MENU", logo: filterButtonLogo, avail: false }].map((menu, index) => {
            return <ToolbarItem key={index} 
                                src={menu.logo} 
                                onItemSelected={e => {
                                    this.setActiveItem(e, menu);
                                }}
                                active={this.state.activeItem && menu.type === this.state.activeItem.type}
                                unavail={!menu.avail} />
        });

        return (
            <div>
                { !this.props.isHidden ? 
                    <div className="toolbar">
                        <a className="menuBtn">
                            <img className="menuLogo" src={vimageDarkLogo} alt="Menu bar" />
                        </a>
                        <div className="splitter">
                        </div>
                        <div className="toolbarMenu">
                            <ul>
                                {listItems}
                            </ul>
                        </div>
                        <div>
                            <ToolbarItem src={settingsButtonLogo} 
                                onItemSelected={e => {
                                    let menu = { title: "INFO", type:"SETTINGS_MENU", avail: true };
                                    this.setActiveItem(e, menu);
                                }}
                                active={this.state.activeItem && "SETTINGS_MENU" === this.state.activeItem.type}
                                unavail={false} />
                        </div>
                    </div> : null 
                }
            </div>
        );
    }
}