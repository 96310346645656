import React, { Component } from "react";
import "./TextEditor.css";
import { ChromePicker } from 'react-color'

export class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: null,
            fontOptions: [],
            fontFamily: "Spectral",
            color: "#ffffff"
        }

        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleColorChange = this.handleColorChange.bind(this);
    }

    fetchFonts() {
        let vimageFonts = [
            {family: "Spectral"}, 
            {family: "Monoton"}, 
            {family: "Kaushan Script"}, 
            {family: "Bubblegum Sans"}, 
            {family: "Lilita One"},
            {family: "Julius Sans One"},
            {family: "Wire One"},
            {family: "Cormorant" },
            {family: "Righteous" },
            {family: "Bungee Shade"},
            {family: "Courgette"}
        ];

        return Promise.resolve(vimageFonts);
    }

    handleTextChange(event) {
        this.setState({text: event.target.value});
    }
    
    handleSelectChange(event) {
        this.setState({fontFamily: event.target.value});
    }

    handleColorChange(color, event) {
        this.setState({color: color.hex});
    }

    componentDidMount() {
        let that = this;

        this.fetchFonts().then((vimageFonts) => {
            that.setState({fontOptions: vimageFonts});
        })
    }
    
    render() {
        return (
            <div>
                <label className="controlLabel">TEXT</label>
                <textarea className="textEdit control" rows={5} onChange={this.handleTextChange}/>

                <label className="controlLabel">FONT</label>
                <select className="fontSelect control" onChange={this.handleSelectChange}>
                    {this.state.fontOptions.map((font, index) => <option key={index} value={font.family}>{font.family}</option>)}
                </select>

                <label className="controlLabel">COLOR</label>
                <ChromePicker color={this.state.color} 
                    onChangeComplete={ this.handleColorChange }
                    className="colorPicker" />

                <input type="button" 
                    className="addBtn"
                    value="ADD" 
                    onClick={e => this.props.onTextAdded(e, { 
                        text: this.state.text, 
                        fontFamily: this.state.fontFamily, 
                        color: this.state.color 
                    })} ></input>
            </div>
        )
    }
}