import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip'
import "./ToolbarItem.css";

export class ToolbarItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false
        }
        this.fooRef = null;
    }
    render() {
        let classNames;
        
        if (this.props.unavail) {
            classNames = "toolbarUnavailItem";
        } else if (this.props.active) {
            classNames = "toolbarItem active";
        } else {
            classNames = "toolbarItem";
        }

        return (
            <div>
                <ReactTooltip id='comingSoon'>
                    <span>Feature is coming soon</span>
                </ReactTooltip>

                {this.props.unavail ?
                    <li data-tip='tooltip' 
                        data-for='comingSoon'
                        className={classNames}
                        onClick={this.props.onItemSelected}>
                        <img src={this.props.src} alt="" />
                    </li> : 
                    <li 
                        className={classNames}
                        onClick={this.props.onItemSelected}>
                        <img src={this.props.src} alt="" />
                    </li> 
                }
            </div>
        );
    }
}