/*global importScripts*/
/*global ffmpeg_run*/
export default function VideConverterWorker(args) {
  importScripts('https://app.vimageapp.com/ffmpeg.js');

  var now = Date.now;

  function print(text) {
    postMessage({
      'type' : 'stdout',
      'data' : text
    });
  }

  self.addEventListener("message", function(event) { // eslint-disable-line no-restricted-globals
    var message = event.data;

    if (message.type === "command") {
      var Module = {
        print: print,
        printErr: print,
        files: message.files || [],
        arguments: message.arguments || [],
        TOTAL_MEMORY: message.TOTAL_MEMORY || false
        // Can play around with this option - must be a power of 2
        // TOTAL_MEMORY: 268435456
      };

      postMessage({
        'type' : 'start',
        'data' : Module.arguments.join(" ")
      });

      postMessage({
        'type' : 'stdout',
        'data' : 'Received command: ' +
                  Module.arguments.join(" ") +
                  ((Module.TOTAL_MEMORY) ? ".  Processing with " + Module.TOTAL_MEMORY + " bits." : "")
      });

      var time = now();
      var result = ffmpeg_run(Module); // eslint-disable-line no-restricted-globals

      var totalTime = now() - time;
      postMessage({
        'type' : 'stdout',
        'data' : 'Finished processing (took ' + totalTime + 'ms)'
      });

      postMessage({
        'type' : 'done',
        'data' : result,
        'time' : totalTime
      });
    }
  });

  postMessage({
    'type' : 'ready'
  });
}