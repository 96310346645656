import React from 'react';
import "./EffectSelectionItem.css";

export class EffectSelectionItem extends React.Component {
    render() {
        return (
            <div>
                <div className="effectSelectionItem" 
                    onClick={ e => this.props.onEffectSelected(e, this.props.effect) } 
                    value={this.props.effect} 
                    key={this.props.effect.dbKey}>
                    <img alt="Background" 
                    src={this.props.effect.previewFrame.url} 
                    width="94" 
                    height="94" />
                </div>
                <p className="effectName">{this.props.effect.name}</p>
            </div>
        )
    }
}