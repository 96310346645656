import React from "react";
import "./SettingsMenu.css";

export class SettingsMenu extends React.Component {
  render() {
    return (
      <div>
        <label className="controlLabel">VERSION</label>
        <p className="secondaryText">VIMAGE Web v0.1.1 (BETA)</p>

        <label className="controlLabel">CODEC</label>
        <p className="secondaryText">H.264</p>

        <label className="controlLabel">SHORTCUTS</label>
        <p className="secondaryText"><kbd>Space</kbd> - freeze effect</p>
        <p className="secondaryText"><kbd>Left/Right Arrow</kbd> - seek effect frames</p>
        <p className="secondaryText"><kbd>Backspace</kbd> - delete object</p>

        <br/>

        <label className="controlLabel">BUG REPORTS</label>
        <a className="link" href="mailto:help@vimageapp.com">help@vimageapp.com</a> 

        <label className="controlLabel">FOLLOW US</label>
        <div className="socialButtons">
          <a href="https://www.facebook.com/vimageapp/" target="_blank" className="fa fa-facebook"></a>
          <a href="https://www.instagram.com/vimageapp/" target="_blank" className="fa fa-instagram"></a>
          <a href="https://www.reddit.com/r/vimage/" target="_blank" className="fa fa-reddit"></a>
        </div>
      </div>
    )
  }
}