import React, { Component } from "react";
import { LayerItem } from "../LayerItem/LayerItem";
import "./LayerPanel.css";

export class LayerPanel extends Component {
    render() {
        const layers = this.props.objects.map((o, index) => 
                <LayerItem key={index} 
                    preview={o.previewFrame.url}
                    onItemSelected={e => {
                        this.props.onLayerItemSelected(e, index);
                    }}>
                    {o.name}
                </LayerItem>);

        return (
            <div>
                {
                    !this.props.isHidden ? 
                    <div className="layerBar">
                        <h4>OBJECTS</h4>
                        <div className="layerList">
                            { layers }
                        </div>
                    </div> : null 
                }
            </div>
        )
    }
}