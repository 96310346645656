import React from "react";
import { EffectSelectionItem } from "../EffectSelectionItem/EffectSelectionItem";
import * as api from '../../lib/api';
import Loader from 'react-loader-spinner'
import "./EffectSelection.css";

export class EffectSelection extends React.Component {
    constructor() {
        super();
        this.state = {
            effects: [],
            loaded: false
        }
        this.originalEffects = [];
    }

    handleInputChange = () => {
        let query = this.search.value;

        if (this.originalEffects.length === 0) {
            this.originalEffects = this.state.effects.slice();
        }
        
        let newEffects = this.originalEffects.filter(function(effect) {
            return effect.name.toLowerCase().includes(query.toLowerCase());
        });

        this.setState({ effects: newEffects });   
    }

    componentDidMount() {
        api.getEffects()
        .then(effects => {
            this.setState({ effects: effects, loaded: true });
        })
        .catch(error => {
            console.log("An error occured fetching effects: " + error);
        });
    }

    render() {
        return (
            <div className="container">
                <div>
                    <p className="panelSubtitle">SEARCH</p>
                    <input type="text" 
                        className="searchInput"
                        ref={input => this.search = input}
                        onChange={this.handleInputChange} />
                </div>

                <div className="effectGrid">
                    { !this.state.loaded ? 
                        <Loader
                            className="loaderSpinner"
                            type="Oval"
                            color="#00BFFF"
                            height={60}
                            width={60}/> :
                        <div className="row">
                            {this.state.effects.map((item, index) => (
                                <EffectSelectionItem key={index} effect={item} onEffectSelected={this.props.onEffectSelected} />
                            ))}
                        </div>
                    }
                </div>
            </div>
        );
    }
}